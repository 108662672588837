import { Component, OnInit, HostListener, ElementRef, Input, DoCheck, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { VerticalHeaderService } from './vertical-header.service';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FullComponent } from '../full.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
	MatSnackBar, MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Helper } from 'src/helper/helper';
import { MatDialog } from '@angular/material/dialog';
import { PriceBlockModalComponent } from 'src/app/price-block-modal/price-block-modal.component';
import { filter } from 'rxjs/operators';
import { CompleteProfileModalGlobal } from 'src/app/complete-profile-modal-global/complete-profile-modal-global.component';
import * as moment from 'moment';
import { VerticalAppSidebarComponent } from './../vertical-sidebar/vertical-sidebar.component';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-vertical-header',
	templateUrl: './vertical-header.component.html',
	styleUrls: ['./vertical-header.component.scss']
})

export class VerticalAppHeaderComponent implements DoCheck, AfterViewInit {

	@Input() callbackFunction!: (element: any) => void;
	@Input() titleKey: any;

	public config: PerfectScrollbarConfigInterface = {};
	isAllowed: boolean = true;
	dataAssociation: any;
	engagementCompleted: any;
	countAssociationPending = 0;
	message: any;
	message1: any;

	menuOpen: any = false;
	versionProduct: any;
	functions: any;
	viewButtonMyProfile = false;
	viewButtonCompanyProfile = false;
	viewButtonManageUsers = false;
	viewButtonMyPlans = false;
	viewButtonManageUsersSupplier = false;

	horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
	verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom

	numberToken: any;
	currentUser: any;
	text: any;
	private wasInside = false;

	creditsTotal: any;
	creditsUser: any;
	namePlanCurrent: any;
	price: any;
	limit: any;
	fullBar: any;

	oldProfile = true;
	allNotifications: any = [];
	countModaleExpired = 0;
	newMessagesLed: any = 0;
	url: any;
	isEsprinet: any = false;
	tutorialMode: any = null;
	descriptionForTutorial: any = '';
	titleForTutorial: any = '';
	futureUrl: any = '';
	is_old_plan: any;
	countPosition: any;
	max_position: any;
	transactionFee: any;
	freePlan: any;
	planStatus: any;
	requestAvailableBlock: any;
	downloadCVBlock: any;
	informationBlock: any;
	emailsContactBlock: any;
	existInLimitationSearch: any;
	currentDocStatus: any;
	getPlanStatusRes: any;

	@HostListener('click')
	clickInside() {
		this.text = "clicked inside";
		this.menuOpen = true;
		this.wasInside = true;
	}

	@HostListener('document:click')
	clickout() {
		if (!this.wasInside) {
			this.text = "clicked outside";
			this.menuOpen = false;
			this.rotateIconDown(0);
			this.rotateIconDown(1);
		}
		this.wasInside = false;
	}

	// This is for Notifications
	notifications: Object[] = [
	];

	public selectedLanguage: any = {
		language: 'English',
		code: 'en',
		type: 'ESP',
		icon: 'ENG'
	}

	public languages: any[] = [{
		language: 'English',
		code: 'en',
		type: 'ESP',
		view: 'ENG',
		icon: 'ENG'
	},
	{
		language: 'Italian',
		code: 'it',
		view: 'ITA',
		icon: 'ITA'
	}]

	profileImage = "assets/images/users/avatarDefault.svg";
	assumedUser: any;
	token: any;
	statusCompanyProfile: any;
	titleInvite: any;
	titlePosition: any;
	message2: any;
	titleCompanyProfile: any;
	titleCreateProject: any;
	message3: any;
	cbInstance: any
	state: any;
	idPlanCurrent: any;
	domain: any;
	showAlertPlan = false;
	isProd = false;
	limitations: any = [];
	limitationsArray: any = [];
	manageUserBlock: any;
	discoverCompatibleProfileBlock: any;
	timeMaterialBlock: any;
	fixedPriceBlock: any;
	showEstimateBlock: any;
	hireBlock: any;
	chatBlock = { blocked: false, action: 1 }!;
	globalState = { idPlanCurrent: 0, isOldPlan: false }!;
	descriptionPages: any = [];
	tutorialToShow: any = { title: '', description: '', exist: false, mode: false }
	viewButtonChat = false;
	downloadCompanyReportBlock: any;
	searchPartnerBlock: any;
	loadedContent = false
	modalAddOn = false
	skippedForSession: any = false;

	constructor(private appComponent: AppComponent, private cdRef: ChangeDetectorRef, public dialog: MatDialog, private http: HttpClient, private translate: TranslateService, private _service: VerticalHeaderService, private router: Router, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public fullComponent: FullComponent, private _snackBar: MatSnackBar, private _helper: Helper) {
		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		this.matIconRegistry.addSvgIcon(
			`notification-bell`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/topbr-icons/notification-bell.svg`)
		);
		console.log(1)
		this.matIconRegistry.addSvgIcon(
			`company-profile-custom`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/new-card-icons/company-white.svg`)
		);
		if (window.location.host == 'marketplace.timeflow.it') {
			this.cbInstance = (<any>window)['Chargebee'].init({
				site: "timeflow"
			});
		} else {
			this.cbInstance = (<any>window)['Chargebee'].init({
				site: "timeflow-test"
			});
		}

		this.cbInstance.setPortalSession(() => {
			return this.http.get(this._helper.getBaseUrl() + 'chargebee/getPortalSession', { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${this._helper.getUserConfigData().access_token}` }) }).toPromise();
		});

		this.functions = JSON.parse(sessionStorage.getItem("functions")!);

		if (this.functions && this.functions.length != 0) {
			for (let i = 0; i < this.functions.length; i++) {
				if (this.functions[i]['state'] == 'my_profile') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonMyProfile = true;
					} else {
						this.viewButtonMyProfile = false;
					}
				}
				if (this.functions[i]['state'] == 'company_profile') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonCompanyProfile = true;
					} else {
						this.viewButtonCompanyProfile = false;
					}
				}
				if (this.functions[i]['state'] == 'manage_users') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonManageUsers = true;
					} else {
						this.viewButtonManageUsers = false;
					}
				}
				if (this.functions[i]['state'] == 'my_plans') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonMyPlans = true;
					} else {
						this.viewButtonMyPlans = false;
					}
				}
				if (this.functions[i]['state'] == 'manage_users_supplier') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonManageUsersSupplier = true;
					} else {
						this.viewButtonManageUsersSupplier = false;
					}
				}
				if (this.functions[i]['state'] == 'chat') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonChat = true;
					} else {
						this.viewButtonChat = false;
					}
				}
			}
		} else {
			this.functions = [];
		}

		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
		if (this.currentUser.language) {
			translate.setDefaultLang(this.currentUser.language);
			this.selectedLanguage.code = this.currentUser.language;

			if (this.currentUser.language == "it") {
				this.selectedLanguage.view = "ITA";
				this.selectedLanguage.language = "Italian";
			} else {
				this.selectedLanguage.view = "ENG";
				this.selectedLanguage.language = "English";
			}
		} else {
			translate.setDefaultLang('en');
		}
		if (this.currentUser.role == "Administrator" || this.currentUser.role == "Supplier Employees" || this.currentUser.role == "Client Employees" || this.currentUser.role == 'Supplier Management'
			|| this.currentUser.role == 'Profile Accademy' || this.currentUser.role == 'Management Accademy') {
			this.isAllowed = false;
		}

		if (this.currentUser.profile_image_url != "" && this.currentUser.profile_image_url != null
			&& this.currentUser.profile_image_name != "" && this.currentUser.profile_image_name != null) {
			switch (this.currentUser.profile_image_name) {
				case 'avatar-woman.svg':
					this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
					break;
				case 'avatar-man.svg':
					this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
					break;
				default:

					this.profileImage = this.currentUser.profile_image_url;
					break;

			}

		}

		this._service.onDataChanged.subscribe(data => {
			if (data.profile_image_url !== null) {
				switch (this.currentUser.profile_image_name) {
					case 'avatar-woman.svg':
						this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
						break;
					case 'avatar-man.svg':
						this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
						break;
					default:
						this.profileImage = this.currentUser.profile_image_url;
						break;
				}
			}
		})

		router.events.subscribe((event: any) => {
			let finder: any;
			if (event instanceof NavigationEnd) {
				finder = event.url;
				this.setTutorialOption(finder)
				this.futureUrl = finder;
				let skipped = sessionStorage.getItem('skippedForSession')
				this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);
				if (skipped && skipped == 'true') {
					this.skippedForSession = true
				} else {
					this.skippedForSession = false
				}
				let already = sessionStorage.getItem('alreadyGlobal')
				if (already == undefined || already == null || !already) {
					already == 'false'
				}
        if(this.currentUser.isProfessionalServices == 0 && this.currentUser.required_survey == 1){
          console.log('stopped modal for not prof');
        }else{
          if (already == 'false' && ((this.currentUser.company_profile_profiled == 0 && this.currentUser.isProfessionalServices == 1 && this.currentUser.required_survey == 1) || (this.currentUser.company_profile_profiled == 0 && this.currentUser.required_survey == 0)) && this.skippedForSession == false && this.assumedUser == 0 && this.currentUser.role == 'Supplier' && !window.location.href.toLowerCase().includes('registration_stepper') && !window.location.href.toLowerCase().includes('authentication') && !window.location.href.toLowerCase().includes('company_profile_supplier')) {
            var obj;
            sessionStorage.setItem('alreadyGlobal', 'true')
            let dialogRef = this.dialog.open(CompleteProfileModalGlobal, {
              data: obj
            });
            dialogRef.afterClosed().subscribe(result => {
              sessionStorage.setItem('alreadyGlobal', 'false')
              let event;
              if (result == undefined) {
                event == 'Close'
              } else {
                event = result.event
              }
              if (event == 'Close') {

              } else if (event == 'Skip') {
                this.skippedForSession = true
                sessionStorage.setItem('skippedForSession', 'true')

              } else if (event == 'Profile Complete' && this.isEsprinet == true) {
                this.router.navigate(['/registration_stepper'])
              }
              else if (event == 'Profile Complete' && this.isEsprinet == false) {
                this.router.navigate(['/procurement_registration_stepper'])
              }
            })
          }
        }
			}

			let tutorial = localStorage.getItem('tutorialMode')!;
			if (tutorial && tutorial == 'true') {
				this.tutorialMode = true
			} else {
				this.tutorialMode = false;
			}

		});

	}

	getUnreadMessages() {
		this._service.getChatNewMessages().then(res => {
			this.newMessagesLed = res.data;
		}).catch(error => {
			if (error.status == 401) {
				this.fullComponent.refreshToken(1);

			} else {
				this.manageError();
			}

		});
	}
	setTutorialOption(val: any) {
		//console.log(val);

		let routeTitle = val;

		let locationFinal = routeTitle;

		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		this.descriptionPages = JSON.parse(sessionStorage.getItem("descriptionPages")!);
		//console.log(this.descriptionPages);
		this.descriptionForTutorial = ''
		this.titleForTutorial = ''
		if (this.currentUser != undefined && this.currentUser != null && this.currentUser.language == "it") {

			this.descriptionPages.it.forEach((element: any) => {
				if (locationFinal.includes(element.path)) {
					this.descriptionForTutorial = element.description;
					this.titleForTutorial = element.title;
				}
			});

		} else if (this.currentUser != undefined && this.currentUser != null && this.currentUser.language == "en") {

			this.descriptionPages.en.forEach((element: any) => {
				if (locationFinal.includes(element.path)) {
					this.descriptionForTutorial = element.description;
					this.titleForTutorial = element.title;
				}
			});

		}

		this.tutorialToShow = { title: this.titleForTutorial, description: this.descriptionForTutorial, exist: this.descriptionForTutorial.length >= 1 || this.titleForTutorial.length >= 1 ? true : false, mode: this.tutorialMode }
		//console.log(this.tutorialToShow);

	}

	setTutorial() {
		//console.log(this.tutorialMode);
		switch (this.tutorialMode) {
			case null:
				this.tutorialMode = true
				break;
			case true:
				this.tutorialMode = false
				break;
			case false:
				this.tutorialMode = true
				break;

			default:
				this.tutorialMode = true
				break;
		}
		localStorage.removeItem('tutorialMode')
		if (this.tutorialMode == true) {
			localStorage.setItem('tutorialMode', 'true')
		} else {
			localStorage.setItem('tutorialMode', 'false')
		}
	}

	openBlockModal(type: any) {
		let dialogRef;
		var obj;
		if (type == 'chat') {
			obj = this.chatBlock;
			obj.action = 1;
		} else {
			obj = this.manageUserBlock;
			obj.action = 2;
		}
		obj.sourcePage = type == 'chat' ? 'Header  - Chat' : 'Header  - Blocco utenze';

		dialogRef = this.dialog.open(PriceBlockModalComponent, {
			data: obj
		});

		dialogRef.afterClosed().subscribe(result => {

		})
	}
	goToNotification() {
		this.router.navigate(['/notifications'])
	}

	ngAfterViewChecked(): void {
		let tutorial = localStorage.getItem('tutorialMode')!;
		if (tutorial && tutorial == 'true') {
			this.tutorialMode = true
		} else {
			this.tutorialMode = false;
		}
		this.manageUserBlock = this.fullComponent.manageUserBlock
		this.discoverCompatibleProfileBlock = this.fullComponent.discoverCompatibleProfileBlock
		this.timeMaterialBlock = this.fullComponent.timeMaterialBlock
		this.fixedPriceBlock = this.fullComponent.fixedPriceBlock
		this.showEstimateBlock = this.fullComponent.showEstimateBlock
		this.hireBlock = this.fullComponent.hireBlock
		this.chatBlock = this.fullComponent.chatBlock
		this.state = this.fullComponent.state
		this.globalState = this.fullComponent.globalState
		////console.log(this.globalState);
		// BLOCCHI DA FULL COMPONENT
		setTimeout(() => {
			this.downloadCompanyReportBlock = this.fullComponent.downloadCompanyReportBlock
			this.searchPartnerBlock = this.fullComponent.searchPartnerBlock

			// BLOCCHI DA FULL COMPONENT
			this.loadedContent = true

			if (this.searchPartnerBlock == undefined || this.searchPartnerBlock == null) {
				this.modalAddOn = false
			} else {
				if (this.searchPartnerBlock.blocked == true) {
					this.modalAddOn = false
				} else {
					this.modalAddOn = true
				}
			}
		}, 1);

		this.numberToken = this.fullComponent.creditAvailable;
		this.creditsTotal = this.fullComponent.credits;
		this.creditsUser = this.fullComponent.creditsUsed;
		this.namePlanCurrent = this.fullComponent.namePlanCurrent;
		this.idPlanCurrent = this.fullComponent.idPlanCurrent;
		this.price = this.fullComponent.price;
		this.limit = this.fullComponent.limit;
		this.oldProfile = this.fullComponent.is_old_plan;
		this.planStatus = this.fullComponent.planStatus;
		this.idPlanCurrent = this.fullComponent.idPlanCurrent;
		this.is_old_plan = this.fullComponent.is_old_plan;
		this.countPosition = this.fullComponent.countPosition;
		this.max_position = this.fullComponent.max_position;
		this.transactionFee = this.fullComponent.transactionFee;
		this.namePlanCurrent = this.fullComponent.namePlanCurrent;
		this.freePlan = this.fullComponent.freePlan;
		this.currentDocStatus = this.fullComponent.currentDocStatus;
		this.getPlanStatusRes = this.fullComponent.getPlanStatusRes;
		this.cdRef.detectChanges();
		this.calculatePercentage(this.creditsTotal, this.creditsUser);

		this.cdRef.detectChanges();

	}

	ngAfterViewInit(): void {
		this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);
    if(this.currentUser.isProfessionalServices == 0 && this.currentUser.required_survey == 1){
      console.log('stopped modal for not prof');
    }else{
      if (this.currentUser.company_profile_profiled == 0 && this.skippedForSession == false && this.assumedUser == 0 && this.currentUser.role == 'Supplier' && !window.location.href.toLowerCase().includes('registration_stepper') && !window.location.href.toLowerCase().includes('authentication') && !window.location.href.toLowerCase().includes('company_profile_supplier')) {
        var obj;
        let dialogRef = this.dialog.open(CompleteProfileModalGlobal, {
          data: obj
        });
        dialogRef.afterClosed().subscribe(result => {
          let event;
          if (result == undefined) {
            event == 'Close'
          } else {
            event = result.event
          }
          if (event == 'Close') {

          } else if (event == 'Skip') {
            this.skippedForSession = true
            sessionStorage.setItem('skippedForSession', 'true')

          } else if (event == 'Profile Complete' && this.isEsprinet == true) {
            setTimeout(() => {
              this.router.navigate(['/registration_stepper'])
            }, 500);
          } else if (event == 'Profile Complete' && this.isEsprinet == false) {
            setTimeout(() => {
              this.router.navigate(['/procurement_registration_stepper'])
            }, 500);
          }
        })

      }
    }

	}

	manageAccount() {
		this.cbInstance.createChargebeePortal().open({
			loaded: function () {

			},
			close: function () {
				//console.log("close portal");
			},
			paymentSourceAdd: function (status: any) {
				//console.log("payment source add" + status);
			},
			paymentSourceUpdate: function (status: any) {
				//console.log("payment source update" + status);
			},
			paymentSourceRemove: function (status: any) {
				//console.log("payment source removed");
			},
			visit: function (value: any) {
				//console.log(value);
			}
		});
	}

	toChat() {
		if (this.currentUser.role == "Client" || this.currentUser.role == 'Client Employees') {
			if (!this.viewButtonChat) {
				this.showAlertPlan = true;
			} else {
				this.router.navigate(['/chat_component']);
			}
		} else {
			this.router.navigate(['/chat_component']);
		}

	}

	closeModalPurchase() {
		this.showAlertPlan = false;

		var objSegment = {};

		objSegment = {
			action: ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Chiusura modale per rifiuto acquisto funzionalità bloccate' : this.assumedUser == 1 ? '[STAFF] Chiusura modale per rifiuto acquisto funzionalità bloccate' : 'Chiusura modale per rifiuto acquisto funzionalità bloccate'),
			source: 'Componente chat'
		};

		if (this.fullComponent.activeSegmentDev == true) {

			this.fullComponent.trackSegment(objSegment);
		}

		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);

		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		if (this.versionProduct == 2) {
			/* IA - SEGMENT */
			if (window.location.host == 'marketplace.timeflow.it') {
				this.fullComponent.trackSegment(objSegment);
			}
		} else {

			if (window.location.host == 'localhost:4200') {

			} else {

				if (window.location.host == "procurement.timeflow.it") {
					this.fullComponent.trackSegment(objSegment);

				} else if (window.location.host == "whitelabel.daytrend.it") {

				} else if (window.location.host == "procurement.daytrend.it") {

				} else if (window.location.host == "dev.mkt.daytrend.it") {

				} else {
					if (!(window.location.host.includes('daytrend'))) {

						this.fullComponent.trackSegment(objSegment);

					}
				}
			}
		}

	}

	goToMyPlans() {
		this.showAlertPlan = false;
		sessionStorage.setItem('backRequest', window.location.href);
		sessionStorage.removeItem('menuVoice');
		sessionStorage.setItem('menuVoice', JSON.stringify('My Plans'));
		this.router.navigate(['/my_plans']);
	}

	calculatePercentage($total: any, $remaining: any) {
		this.fullBar = (100 * (parseInt($remaining, 10))) / parseInt($total, 10);
		this.fullBar = Math.floor(this.fullBar);
		this.fullBar = 100 - this.fullBar;
	}

	getAssociation() {
		let currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		this._service.getAssociation().then(res => {
			this.dataAssociation = res.data;
			for (let i = 0; i < this.dataAssociation.length; i++) {
				if (this.dataAssociation[i]['status'] == 0 && this.dataAssociation[i]['type_invitation'] == 'association' && currentUser.company_id != this.dataAssociation[i]['company_id_sender']) {
					this.countAssociationPending++;
				}
			}
			if (this.countAssociationPending != 0) {

				if (this.countAssociationPending && this.countAssociationPending == 1) {
					if (this.currentUser.language == "it") {
						this.message = this.translate.translations.it.Have + this.countAssociationPending + this.translate.translations.it.InviteAssociationPending;
					} else {
						this.message = this.translate.translations.en.Have + this.countAssociationPending + this.translate.translations.en.InviteAssociationPending;
					}
				} else {
					if (this.currentUser.language == "it") {
						this.message = this.translate.translations.it.Have + this.countAssociationPending + this.translate.translations.it.InvitesAssociationPending;
					} else {
						this.message = this.translate.translations.en.Have + this.countAssociationPending + this.translate.translations.en.InvitesAssociationPending;
					}
				}

				if (this.currentUser.language == "it") {
					this.titleInvite = this.translate.translations.it.Invite;
				} else {
					this.titleInvite = this.translate.translations.en.Invite;
				}

				this.notifications.push(
					{
						round: 'round-info',
						icon: 'ti-user',
						title: this.titleInvite,
						subject: this.message,
						time: ''
					}
				)
			}
		}).catch(error => {
			if (error.status == 401) {
				this.fullComponent.refreshToken(1);

			} else {
				this.manageError();
			}

		});
	}

	getStatusCompletedCompanyProfile() {
		this._service.getStatusCompletedCompanyProfile().then(res => {
			this.statusCompanyProfile = res.data;

			if (this.statusCompanyProfile == 0) {

				if (this.currentUser.language == "it") {
					this.message2 = this.translate.translations.it.CompleteCompanyProfile;
				} else {
					this.message2 = this.translate.translations.en.CompleteCompanyProfile;
				}

				if (this.currentUser.language == "it") {
					this.titleCompanyProfile = this.translate.translations.it.TitleCompleteCompanyProfile;
				} else {
					this.titleCompanyProfile = this.translate.translations.en.TitleCompleteCompanyProfile;
				}

				this.notifications.push(
					{
						round: 'round-info',
						icon: 'ti-user',
						title: this.titleCompanyProfile,
						subject: this.message2,
						time: ''
					}
				)
			}
		}).catch(error => {
			if (error.status == 401) {
				this.fullComponent.refreshToken(1);

			} else {
				this.manageError();
			}

		});
	}

	ngOnInit(): void {

		let currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);
		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

		if (window.location.hostname.toLowerCase().includes('esprinet')) {
			this.isEsprinet = true
		}

		if (currentUser.role != "Administrator" && currentUser.role != "Supplier Employees" && currentUser.role != "Profile Accademy" && currentUser.role != "Accademy" && currentUser.role != "Management Accademy" && currentUser.role != "Admin Management") {
			this.getAssociation();

		}
		if (currentUser.role == "Client" || currentUser.role == "Client Employees") {

		}

		if (currentUser.role == "Client" || currentUser.role == "Supplier" || currentUser.role == "Accademy") {
			this.getStatusCompletedCompanyProfile();
		}

		this._service.getAllNotifications().then(res => {
			this.allNotifications = res.data.counterNotificationToRead
		}).catch(error => {
			if (error.status == 401) {
				this.fullComponent.refreshToken(1);

			} else {
				this.manageError();
			}

		});
		this.getUnreadMessages()

		this.refreshNotification();

	}
	refreshNotification() {

		setTimeout(() => {
			//console.log('entrato');
			this._service.getAllNotifications().then(res => {
				//console.log(res);
				this.allNotifications = res.data.counterNotificationToRead
				//console.log(this.allNotifications);
				if (this.allNotifications >= 1) {
					let player = document.getElementById('playbar');
					player!.innerHTML = '<audio src="assets/sounds/notification_sound.mp3" type="audio/mpeg" class="song" autoplay>';
				}
			}).catch(error => {
				if (error.status == 401) {
					this.fullComponent.refreshToken(1);

				} else {
					this.manageError();
				}

			});
			this._service.getChatNewMessages().then(res => {
				this.newMessagesLed = res.data
				if (this.newMessagesLed >= 1) {
					let player = document.getElementById('playbar');
					player!.innerHTML = '<audio src="assets/sounds/notification_sound.mp3" type="audio/mpeg" class="song" autoplay>';
				}
			}).catch(error => {
				if (error.status == 401) {
					this.fullComponent.refreshToken(1);

				} else {
					this.manageError();
				}

			});

			this.refreshNotification();
		}, 180000);
	}

	ngDoCheck() {
		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		let currentUrl = window.location.href;
		if (!currentUrl.includes('authentication')) {
			if (this.currentUser != undefined && this.currentUser != null && this.currentUser.profile_image_url != null && this.currentUser.profile_image_url != undefined && this.currentUser.profile_image_url != "" && this.currentUser.profile_image_url != null
				&& this.currentUser.profile_image_name != "" && this.currentUser.profile_image_name != null) {
				switch (this.currentUser.profile_image_name) {
					case 'avatar-woman.svg':
						this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
						break;
					case 'avatar-man.svg':
						this.profileImage = 'assets/images/avatars/' + this.currentUser.profile_image_name;
						break;
					default:
						this.profileImage = this.currentUser.profile_image_url;
						break;
				}
			}
			let images = (sessionStorage.getItem("imageFreelance")!)
			if (images) {
				this.profileImage = images;
				sessionStorage.removeItem("imageFreelance");
			}
		}
		var completeProfile = sessionStorage.getItem("complete-profile");
		var planPurchased = sessionStorage.getItem('plan-purchased');

		if (completeProfile == "true") {
			this.notifications = [];
			sessionStorage.removeItem("complete-profile");
		}
		if (planPurchased == 'true') {
			if (this.currentUser.role == 'Client' || this.currentUser.role == 'Client Employees') {

				var invocateGetNumberCredits = JSON.parse(sessionStorage.getItem('invocateGetNumberCredits')!);

				if ((invocateGetNumberCredits && invocateGetNumberCredits == 0 || !invocateGetNumberCredits)) {

					var dateInvocateGetNumberCredits = JSON.parse(sessionStorage.getItem('dateInvocateGetNumberCredits')!);
					var dateInvocateGetNumberCreditsRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetNumberCreditsRefresh')!);

					if (!dateInvocateGetNumberCreditsRefresh || dateInvocateGetNumberCredits != dateInvocateGetNumberCreditsRefresh) {
						this.fullComponent.getNumberCredits();
					}

					if (this.fullComponent.namePlanCurrent) {

						this.numberToken = this.fullComponent.creditAvailable;
						this.creditsTotal = this.fullComponent.credits;
						this.creditsUser = this.fullComponent.creditsUsed;
						this.namePlanCurrent = this.fullComponent.namePlanCurrent;
						this.idPlanCurrent = this.fullComponent.idPlanCurrent;
						this.price = this.fullComponent.price;
						this.limit = this.fullComponent.limit;
						this.oldProfile = this.fullComponent.is_old_plan;

						sessionStorage.removeItem("plan-purchased");

						this.calculatePercentage(this.creditsTotal, this.creditsUser);

					}
				} else {

					const format1 = "YYYY-MM-DD HH:mm:ss"
					var date2 = new Date();
					var date_for_check = moment(date2).format(format1);
					sessionStorage.setItem('dateInvocateGetNumberCreditsRefresh', JSON.stringify(date_for_check));

				}

			}
			if (this.currentUser.role == 'Supplier' || this.currentUser.role == 'Supplier Employees' || this.currentUser.role == 'Supplier Management') {
				var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

				if ((invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {

					var dateInvocateGetPlanStatu = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatu')!);
					var dateInvocateGetPlanStatuRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatuRefresh')!);

					if (!dateInvocateGetPlanStatuRefresh || dateInvocateGetPlanStatu != dateInvocateGetPlanStatuRefresh) {
						this.fullComponent.getPlanStatus();
					}

					if (this.fullComponent.namePlanCurrent) {

						this.idPlanCurrent = this.fullComponent.idPlanCurrent;
						this.is_old_plan = this.fullComponent.is_old_plan;
						this.countPosition = this.fullComponent.countPosition;
						this.max_position = this.fullComponent.max_position;
						this.transactionFee = this.fullComponent.transactionFee;
						this.namePlanCurrent = this.fullComponent.namePlanCurrent;
						this.freePlan = this.fullComponent.freePlan
						this.price = this.fullComponent.price;
						this.limit = this.fullComponent.limit;

						this.planStatus = this.fullComponent.planStatus;

						this.freePlan = this.fullComponent.freePlan;

						this.limitations = this.fullComponent.limitations;
						sessionStorage.removeItem('userLimitation');
						sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
						this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
						//console.log(this.limitationsArray);
						this.limitationsArray.forEach((element: any) => {
							switch (element.state_function) {
								case "manage_users_supplier":
									this.manageUserBlock = element
									break;
								case "SPC":
									this.discoverCompatibleProfileBlock = element
									break;
								case "tEm":
									this.timeMaterialBlock = element
									break;
								case "PFP":
									this.fixedPriceBlock = element
									break;
								case "VP":
									this.showEstimateBlock = element
									break;
								case "accademy_profiles":
									this.hireBlock = element
									break;
								case "chat":
									this.chatBlock = element
									break;
								case "request_available":
									this.requestAvailableBlock = element
									break;
								case "download_cv":
									this.downloadCVBlock = element
									break;
								case "information_blocked":
									this.informationBlock = element
									break;
								case "emails_contact":
									this.emailsContactBlock = element
									break;
								case "download_company":
									this.downloadCompanyReportBlock = element
									break;
								case "search_partner":
									this.searchPartnerBlock = element
									this.existInLimitationSearch = true;
									break;
								default:
									break;
							}
						});

						if (this.existInLimitationSearch == false) {
							this.searchPartnerBlock = { blocked: true, action: 1 };
							this.downloadCompanyReportBlock = { blocked: true, action: 1 };
						}

					}
				} else {

					const format1 = "YYYY-MM-DD HH:mm:ss"
					var date2 = new Date();
					var date_for_check = moment(date2).format(format1);
					sessionStorage.setItem('dateInvocateGetPlanStatusRefresh', JSON.stringify(date_for_check));

				}
			}

		}
	}

	manageError() {
		if (this.currentUser.language == "it") {
			var errorMessage = this.translate.translations.it.ErrorServer;
		} else {
			var errorMessage = this.translate.translations.en.ErrorServer;
		}
		this._snackBar.open(errorMessage, '', {
			duration: 5000,
			panelClass: ['danger-snackbar'],
			horizontalPosition: this.horizontalPosition,
			verticalPosition: this.verticalPosition,

		});
	}

	changeLanguage(lang: any) {
		this.translate.use(lang.code)
		this.selectedLanguage = lang;
		this._service.updateLanguage(lang.code).then(res => {
			if (res.success) {
				sessionStorage.removeItem('loginUser');
				sessionStorage.setItem('loginUser', JSON.stringify(res.data.user));
				this.callbackFunction(this.titleKey);
				this.setTutorialOption(this.futureUrl);
				window.location.reload();
			}

		}).catch(error => {
			if (error.status == 401) {
				this.fullComponent.refreshToken(1);

			} else {
				this.manageError();
			}

		});
	}

	returnAdmin() {
		console.log('route');

		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		this.url = window.location.hostname;

		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		this.url = window.location.hostname;

		this._service.getParamLogin(this.currentUser.token).then(res => {
			if (res.success) {

				var jsonParse = JSON.parse(res.data.loginUser);
				var assumedUser = jsonParse.assumedUser;
				var possibleAssumeRole = jsonParse.possibleAssumeRole

				sessionStorage.removeItem('loginUser');
				sessionStorage.removeItem('loginUserToken');
				sessionStorage.removeItem('descriptionPages');
				sessionStorage.removeItem('tutorialPage');
				sessionStorage.removeItem('assumedUser');
				sessionStorage.removeItem('versionProduct');
				sessionStorage.removeItem('functions');
				sessionStorage.removeItem('possibleAssumeRole');
				sessionStorage.removeItem('showTypeform');

				sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(res.data.loginUser)));
				sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(res.data.loginUserToken)));
				if (res.data.descriptionPages) {
					sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(res.data.descriptionPages)));
				}
				if (res.data.tutorialPage) {
					sessionStorage.setItem('tutorialPage', JSON.stringify(JSON.parse(res.data.tutorialPage)));
				}
				sessionStorage.setItem('assumedUser', JSON.stringify(JSON.parse(assumedUser)));
				sessionStorage.setItem('versionProduct', JSON.stringify(JSON.parse(res.data.versionProduct)));
				sessionStorage.setItem('functions', JSON.stringify(JSON.parse(res.data.functions)));
				sessionStorage.setItem('possibleAssumeRole', JSON.stringify(JSON.parse(possibleAssumeRole)));

				sessionStorage.setItem('showTypeform', JSON.stringify(0));

				this.domain = res.data.domain_client + '/authentication/redirect_login/' + this.currentUser.token;
				window.location = this.domain;

			} else {
				this.router.navigate(['/authentication/login']);
			}
		}).catch(error => {

			this.router.navigate(['/authentication/login']);

		});

		// let _1_loginUser: any = JSON.parse(sessionStorage.getItem("_1_loginUser")!)
		// let _loginUser: any = JSON.parse(sessionStorage.getItem("_loginUser")!)
		// let zloginUser: any = JSON.parse(sessionStorage.getItem("loginUser")!)

		// let have_1_login_user = false;
		// let have_login_user = false;
		// let havelogin_user = false;

		// if (_1_loginUser != undefined && _1_loginUser != null) {
		//   have_1_login_user = true
		// }
		// if (_loginUser != undefined && _loginUser != null) {
		//   have_login_user = true
		// }
		// if (zloginUser != undefined && zloginUser != null) {
		//   havelogin_user = true
		// }

		// if (this.versionProduct == 1) {

		//   if (have_login_user == true && have_1_login_user == true) {

		//     /* IA - Provengo da platform e ho seguito il seguente percoso staffTimeflow->BackOffice->Azienda cliente/fornitore */
		//     /* IA - Ora sono in un'azienda cliente e devo ritornare nel BackOffice */
		//     sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUser")!)));

		//     sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUserToken")!)));
		//     sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));

		//     sessionStorage.removeItem('_1_loginUser');
		//     sessionStorage.removeItem('_1_loginUserToken');
		//     sessionStorage.removeItem('_descriptionPages');

		//     sessionStorage.removeItem('loginUserSupplier');
		//     sessionStorage.removeItem('loginUserTokenSupplier');
		//     sessionStorage.removeItem('descriptionPagesSupplier');
		//     sessionStorage.removeItem('tutorialPageSupplier');

		//   } else if (have_1_login_user == true && have_login_user == false) {

		//     /* IA - Provengo da backoffice e ho seguito il seguente percoso BackOffice->Azienda cliente/fornitore */
		//     /* IA - Ora sono nel backOffice e devo ritornare in platform */

		//     sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUser")!)));
		//     sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUserToken")!)));
		//     sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));
		//     sessionStorage.setItem('assumedUser', JSON.stringify(0));

		//     sessionStorage.removeItem('_1_loginUser');
		//     sessionStorage.removeItem('_1_loginUserToken');
		//     sessionStorage.removeItem('_descriptionPages');
		//     sessionStorage.removeItem('loginUserSupplier');
		//     sessionStorage.removeItem('loginUserTokenSupplier');
		//     sessionStorage.removeItem('descriptionPagesSupplier');
		//     sessionStorage.removeItem('tutorialPageSupplier');

		//   } else {
		//     sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUser")!)));
		//     sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUserToken")!)));
		//     sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));
		//     sessionStorage.setItem('assumedUser', JSON.stringify(0));

		//     sessionStorage.removeItem('_loginUser');
		//     sessionStorage.removeItem('_loginUserToken');
		//     sessionStorage.removeItem('_descriptionPages');
		//     sessionStorage.removeItem('loginUserSupplier');
		//     sessionStorage.removeItem('loginUserTokenSupplier');
		//     sessionStorage.removeItem('descriptionPagesSupplier');
		//     sessionStorage.removeItem('tutorialPageSupplier');
		//   }

		//   var loginUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		//   if (this.url.includes("esprinet")) {
		//     this.domain = loginUser.redirect_url + '/company_list';
		//   } else if (!this.url.includes("esprinet") && loginUser.logicRole == 'supplier') {
		//     this.domain = loginUser.redirect_url + '/dashboard_supplier';
		//     sessionStorage.setItem('functions', JSON.stringify(JSON.parse(sessionStorage.getItem("functionsSupplier")!)));
		//   } else if (!this.url.includes("esprinet") && loginUser.logicRole == 'admin') {
		//     this.domain = loginUser.redirect_url + '/company';
		//     sessionStorage.setItem('functions', JSON.stringify(JSON.parse(sessionStorage.getItem("functionsAdmin")!)));
		//   } else {
		//     this.domain = loginUser.redirect_url + '/dashboard_supplier';
		//     sessionStorage.setItem('functions', JSON.stringify(JSON.parse(sessionStorage.getItem("functionsSupplier")!)));
		//   }

		//   window.location = this.domain;

		// } else {
		//   sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUser")!)));
		//   sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUserToken")!)));
		//   sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));
		//   sessionStorage.setItem('assumedUser', JSON.stringify(0));

		//   sessionStorage.removeItem('_loginUser');
		//   sessionStorage.removeItem('_loginUserToken');
		//   sessionStorage.removeItem('_descriptionPages');
		//   sessionStorage.removeItem('loginUserSupplier');
		//   sessionStorage.removeItem('loginUserTokenSupplier');
		//   sessionStorage.removeItem('descriptionPagesSupplier');
		//   sessionStorage.removeItem('tutorialPageSupplier');

		//   this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		//   if (this.versionProduct == 2) {
		//     window.location.href = '/company';
		//   } else if (this.versionProduct == 1) {
		//     var loginUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		//     this.domain = loginUser.redirect_url + '/company';
		//     window.location = this.domain;
		//   }
		// }

	}

	goToOtherPage(page: any) {
		sessionStorage.removeItem('menuVoice');
		if (page == 'my_profile') {
			sessionStorage.setItem('menuVoice', JSON.stringify('My Profile'));
			this.router.navigate(['/my_profile']);
		} else if (page == 'company_profile') {
			sessionStorage.setItem('menuVoice', JSON.stringify('Company Profile'));
			this.router.navigate(['/company_profile']);
		} else if (page == 'my_plans') {
			sessionStorage.setItem('menuVoice', JSON.stringify('My Plans'));
			this.router.navigate(['/my_plans']);
		} else if (page == 'student_profile') {
			sessionStorage.setItem('menuVoice', JSON.stringify('Student Profile'));
			this.router.navigate(['/student_profile']);
		} else if (page == 'my_plans_supplier') {
			sessionStorage.setItem('menuVoice', JSON.stringify('My Plans'));
			this.router.navigate(['/my_plans_supplier']);
		} else if (page == 'supplier') {
			sessionStorage.setItem('menuVoice', JSON.stringify('Supplier Profile'));
			this.router.navigate(['/supplier_profile']);
		} else if (page == 'manage_users') {
			//console.log(this.manageUserBlock)
			if (this.manageUserBlock.blocked && this.manageUserBlock.blocked_for_limit_detail && this.manageUserBlock.blocked_for_limit_detail.number_max_limit == 1) {
				this.openBlockModal('manage');
			} else {
				sessionStorage.setItem('menuVoice', JSON.stringify('Manage Users Client'));
				this.router.navigate(['/manage_users']);
			}
		} else if (page == 'manage_users_supplier') {
			if (this.manageUserBlock.blocked && this.manageUserBlock.blocked_for_limit_detail && this.manageUserBlock.blocked_for_limit_detail.number_max_limit == 1) {
				this.openBlockModal('manage');
			} else {
				sessionStorage.setItem('menuVoice', JSON.stringify('Manage Users Supplier'));
				this.router.navigate(['/manage_users_supplier']);
			}
		} else if (page == 'manage_company_admin_users') {
			sessionStorage.setItem('menuVoice', JSON.stringify('Manage Users Company Admin'));
			this.router.navigate(['/manage_company_admin_users']);
		}

	}

	changePages(notification: any) {
		let currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		if (this.currentUser.language == "it") {
			if (notification.title == this.translate.translations.it.Invite) {
				if (currentUser.role == "Client" || currentUser.role == 'Client Employees') {
					sessionStorage.setItem('menuVoice', JSON.stringify('Supplier List'));
					this.router.navigate(['/supplier_profile']);
				} else if (currentUser.role == "Supplier" || currentUser.role == 'Supplier Management') {
					sessionStorage.setItem('menuVoice', JSON.stringify('Customer Profile'));
					this.router.navigate(['/customer_profile']);
				}
			} else if (notification.title == this.translate.translations.it.PositionsOpen) {
				sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
				this.router.navigate(['/need']);
			} else if (notification.title == this.translate.translations.it.TitleCompleteCompanyProfile) {
				this.router.navigate(['/company_profile']);
				sessionStorage.setItem('menuVoice', JSON.stringify('Company Profile'));
			} else if (notification.title == this.translate.translations.it.TitleCreateProject) {
				this.router.navigate(['/need']);
				sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
			}
		} else if (this.currentUser.language == "en") {
			if (notification.title == this.translate.translations.en.Invite) {
				if (currentUser.role == "Client" || currentUser.role == 'Client Employees') {
					sessionStorage.setItem('menuVoice', JSON.stringify('Supplier List'));
					this.router.navigate(['/supplier_profile']);
				} else if (currentUser.role == "Supplier" || currentUser.role == 'Supplier Management') {
					sessionStorage.setItem('menuVoice', JSON.stringify('Customer Profile'));
					this.router.navigate(['/customer_profile']);
				}
			} else if (notification.title == this.translate.translations.en.PositionsOpen) {
				this.router.navigate(['/need']);
				sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
			} else if (notification.title == this.translate.translations.en.TitleCompleteCompanyProfile) {
				this.router.navigate(['/company_profile']);
				sessionStorage.setItem('menuVoice', JSON.stringify('Company Profile'));
			} else if (notification.title == this.translate.translations.en.TitleCreateProject) {
				this.router.navigate(['/need']);
				sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
			}
		}

	}

	rotateIcon(iconnumber: any) {
		let icons = document.getElementsByClassName("chevron-down-custom");
		for (let i = 0; i < icons.length; i++) {
			icons[iconnumber].setAttribute("style", " transform: rotate(180deg)");
		}
	}

	rotateIconDown(iconnumber: any) {
		let icons = document.getElementsByClassName("chevron-down-custom");
		for (let i = 0; i < icons.length; i++) {
			icons[iconnumber].setAttribute("style", " transform: rotate(-360deg)");
		}
	}

	logout() {

		if ((window.location.host == 'marketplace.timeflow.it' && !window.location.host.includes('open/') && this.versionProduct == 2)) {

			setTimeout(() => {
				const status = window.HubSpotConversations.widget.status();

				if (status.loaded) {
					window.HubSpotConversations.widget.refresh();
				} else {
					window.HubSpotConversations.widget.load();
				}

			}, 500);
		}

		sessionStorage.clear();
		localStorage.clear();
		this.appComponent.doneInit = false
		sessionStorage.removeItem('keyCover');
		this.router.navigate(['/']).then(this.appComponent.ngOnInit);

	}

}
